<template>
  <v-container fluid>
    <!---BOC:[breadcrumb]-->
    <ABreadcrumb :items="breadcrumb.items" />
    <!--EOC-->
    <!---BOC:[header]-->
    <BreadHeader :title="`Edit ${modelName}`"></BreadHeader>
    <!--EOC-->
    <!--BOC:[error]-->
    <AError :api="apiRead" />
    <!--EOC-->
    <!---BOC:[form]-->
    <BreadForm
      :isLoading="apiRead.isLoading"
      ref="form"
      action="edit"
      :api="api"
      :model="model"
    />
    <!--EOC-->
    <!---BOC:[note]-->
    <ADevNote
      :text="[
        'Validation',
        ['✗ Validate authority', '✗ Validate ownership'],
        'Layout',
        ['✓ Show breadcrumbs', '✓ Show title', '✓ Replace model item name'],
        'Read',
        ['✓ Run API Read', '✓ Show API Read Loading', '✓ Show API Read Error'],
        'Form',
        [
          '✓ Show Form',
          '✓ Get Form Field',
          '✓ Get Form Rules',
          '✓ Get Form Data',
        ],
        'Action',
        [
          '✓ Form Button isLoading',
          '✓ Run Action API',
          '✓ Show Action API Loading',
          '✓ Show Action API Error',
        ],
        'Parent',
        [
          '✓ Fetch data',
          '✓ Show loading',
          '✗ Show error',
          '✓ Show null',
          '✗ Select default value',
        ],
      ]"
    />
    <!--EOC-->
  </v-container>
</template>

<script>
//BOC:[api]
import Api from "@/objects/api";
//EOC
//BOC:[breadcrumb]
import Breadcrumb from "@/objects/breadcrumb";
//EOC
//BOC:[model]
import Model from "@/objects/model";
//EOC
//BOC:[service]
import Service from "@/objects/service";
//EOC
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    services: (state) => state.service.data.services,
  }),
  props: {
    //
  },
  data: () => ({
    //BOC:[apiRead]
    apiRead: new Api(),
    //EOC
    //BOC:[api]
    api: new Api(),
    //EOC
    //BOC:[breadcrumbs]
    breadcrumb: new Breadcrumb(),
    //EOC
    //BOC:[model]
    model: new Model(),
    modelId: null,
    modelName: null,
    //EOC
    //BOC:[service]
    service: new Service(),
    //EOC
  }),
  created() {
    //BOC:[model]
    this.model.getByKey(this.$route.params.modelKey);
    this.modelId = this.$route.params.modelId;
    this.modelName = `${this.model.name.singular} ${this.modelId}`;
    //EOC
    //BOC:[service]
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);
    //EOC
    //BOC:[breadcrumbs]
    this.breadcrumb.addForOperationEdit({
      services: this.services,
      model: this.model,
      modelId: this.modelId,
    });
    //EOC
    //BOC:[apiRead]
    this.apiRead.setUrl(
      `${this.$service[this.service.key]}/v1/en/console/model/${
        this.model.key
      }/${this.modelId}`
    );
    this.apiRead.setCallbackCompleted((resp) => {
      this.$refs.form.setData(resp);
      if (resp.name) this.modelName = resp.name;
    });
    this.apiRead.fetch();
    //EOC
    //BOC:[api]
    this.api.setMethod(`POST`);
    this.api.setUrl(
      `${this.$service[this.service.key]}/v1/en/console/model/${
        this.model.key
      }/${this.modelId}/edit`
    );
    this.api.setCallbackCompleted(() => {
      this.$router.push({
        name: "PageServiceModelRead",
        params: {
          serviceKey: this.model.serviceKey,
          modelKey: this.model.key,
          modelId: this.modelId,
        },
      });
      this.$store.dispatch("showMessage", "Updated successfully.");
    });
    //EOC
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>